// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap")
require("@fortawesome/fontawesome-free")
require("select2")
require("webpack-jquery-ui")

import "@fortawesome/fontawesome-free/css/all"
import ApexCharts from 'apexcharts'
window.ApexCharts = ApexCharts

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// import "controllers";

var jQuery = require('jquery')

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
window.flatpickr = require('flatpickr');
global.toastr = require("toastr")
global.toastr.options = {
  "closeButton": true,
  "newestOnTop": true,
  "progressBar": true,
  "positionClass": "toast-custom-position",
  "timeOut": "1500",
  "extendedTimeOut": "1000"
}

require("@nathanvda/cocoon")
